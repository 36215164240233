.ctn {
    max-width: 320px;
    margin: 0 auto;
    padding: 10px;
}

.file {
    width: 320px;
    height: 320px;
    border-radius: 20px;
    object-fit: cover;
    margin: 0 auto;
}

.class {
    color: #707070;
    font-size: 14px;
    font-weight: normal;
    border: 1px solid #E4E7F7;
    border-radius: 6px;
    padding: 10px;
    width: 66px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0px;
}

.title {
    color: #0F1749;
    letter-spacing: 0px;
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 0px;
}

.description {
    font-size: 16px;
    letter-spacing: 0px;
    color: #4E4E4E;
    margin-top: 10px;
    margin-bottom: 0px;
}

.reward {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 0px;
}

.reward .facil {
    color: white;
    background: #50ff43 0% 0% no-repeat padding-box;
    border-radius: 6px;
    max-width: 150px;
    text-align: center;
    font-weight: bold;
    padding: 10px;
}

.reward .media {
    color: white;
    background: #FFA943 0% 0% no-repeat padding-box;
    border-radius: 6px;
    max-width: 150px;
    text-align: center;
    font-weight: bold;
    padding: 10px;
}

.reward .dificil {
    color: white;
    background: #ff4343 0% 0% no-repeat padding-box;
    border-radius: 6px;
    width: 145px;
    height: 50px;
    text-align: center;
    font-weight: bold;
    padding: 10px;
}

.reward .div-reward {
    background: #F5F6FA 0% 0% no-repeat padding-box;
    border-radius: 10px;
    width: 145px;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.reward .div-reward .text-reward {
    font-size: 13px;
    color: #707070;
    margin: 0;
    width: 100%;
    text-align: center;
}

.reward .div-reward .value-reward {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    width: 100%;
    text-align: center;
}

.apps {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 0px;
}

.apps .play-store,
.apps .apple-store {
    width: 140px;
    transition: all .3s ease-in-out;

}

.apps .play-store :hover,
.apps .apple-store :hover {
    width: 145px;
}